import { RequestLoginCodePayload } from '@tebuto/api/clients/models/RequestLoginCodePayload'
import { useClientsApiMutation } from '@tebuto/functions/hooks'
import { useUnauthorizedClientTranslation } from '@tebuto/functions/translation.page'
import { useRouter } from 'next/navigation'
import { useContext } from 'react'
import { LoginStepContext, SendCodeFormValues } from './LoginForm'

// Mutations

export function useRequestCode(startTimer: (seconds: number) => void) {
    const { t } = useUnauthorizedClientTranslation()
    const { setStep, setEmail } = useContext(LoginStepContext)

    const { trigger: requestCode, isMutating } = useClientsApiMutation(
        'requestCode',
        (api, values: RequestLoginCodePayload) => {
            const email = values.email.replace(/\s+/g, '')
            api.auth.requestLoginCode({ email }).then(() => {
                setEmail(email)
                setStep('sendCode')
                startTimer(60)
            })
        },
        { successMessage: t('loginPage.form.sendCode.requestNewCodeDialog.newCodeSent') }
    )
    return { requestCode, isMutating }
}

export function useSendCode(setError: (error: string) => void) {
    const router = useRouter()
    const { t } = useUnauthorizedClientTranslation()

    const { trigger } = useClientsApiMutation(
        'sendCode',
        (api, values: SendCodeFormValues) =>
            api.auth
                .login({ code: values.otp })
                .then(() => {
                    localStorage.removeItem('loginStep')
                    localStorage.removeItem('email')
                    router.push('/dashboard')
                })
                .catch(() => setError(t('loginPage.form.sendCode.requestNewCodeDialog.invalidCode'))),
        { successMessage: null }
    )

    return trigger
}

'use client'

import { useTranslation } from 'react-i18next'
import { useCurrentUser, useSelectedClientWhoAmITherapist, useSelectedTherapistId } from './hooks'

export function useClientTranslation() {
    const therapist = useSelectedClientWhoAmITherapist()
    return useTranslation(['common', 'client', `client-${therapist.type}`], { nsMode: 'fallback' })
}

export function useUnauthorizedClientTranslation() {
    return useTranslation(['common', 'client'], { nsMode: 'fallback' })
}

export function useAppTranslation() {
    const user = useCurrentUser()
    const therapistId = useSelectedTherapistId()
    const therapist = user.therapists.find(linking => linking.therapist.id === therapistId)?.therapist
    return useTranslation(['common', 'app', `app-${therapist?.type}`], { nsMode: 'fallback' })
}
